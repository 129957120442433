/* header */
.header {
    position: fixed;
    width: 100%;
    z-index: 1000;
    background: #000;
    background: #fff;
    box-shadow: 0 10px 100px rgba(0, 0, 0, .1);
}

.header__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10.4px 52px;

}

.header__logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #333;
    transition: color .3s;
}

.header__sm-menu {
    background: #fff;
    position: absolute;
    width: 100%;
    top: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
}

.header__sm-menu.visible {
    visibility: visible;
    opacity: 1;

}

.header__links {
    display: flex;
}

.header__logo-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    display: block;
}

.header__logo-img-cont {
    width: 52px;
    height: 52px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 1.5rem;
    background: #facf0f;
}

.header__logo-sub {
    font-size: 18.72px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
}

.header__logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #333;
    transition: color .3s;
}

.header__links {
    display: flex;
}

.header__link {
    padding: 22px 31px;
    display: inline-block;
    font-size: 16.64px;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    transition: color .3s;
}


.header__main-ham-menu-cont {
    display: none;
    width: 30px;
}

.header__main-ham-menu {
    width: 100%;
}

.header__main-ham-menu-close {
    width: 100%;
}

.d-none {
    display: none;
}

.header__sm-menu-link a {
    display: block;
    text-decoration: none;
    padding: 25px 30px;
    font-size: 16px;
    color: #333;
    text-align: center;
    border-bottom: 1px solid #eee;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: color .3s;
}

.header__sm-menu-link:first-child a {
    border-top: 1px solid #eee;
}

/* screen sizes 990px and below */
@media screen and (max-width: 990px) {
    .header__link {
        padding: 20px 10px;
        font-size: 14.5px;
        display: none;
    }

    .header__main-ham-menu-cont {
        display: block;
    }
}

@media screen and (max-width: 576px) {
    .header__content {
        padding: 10.4px 20px;
    }

    .header__sm-menu-link a {

        padding: 14px 30px;

    }
}