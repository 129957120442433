.main-footer {
    background: #000;
    color: #fff;
}

.main-footer__upper {
    display: flex;
    justify-content: space-between;
    padding: 80px 0;
}

.main-footer__row-1 {
    order: 2;
}

.heading-sm {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}



.main-footer__lower {
    padding: 40px 0;
    border-top: 1px solid #444;
    color: #eee;
    font-size: 12px;
    text-align: left;
    text-align: center;
}

.main-footer__lower a {
    text-decoration: underline;
    font-weight: 700;
    margin-left: 2px;
}

.main-footer__social-cont {
    margin-top: 20px;
}

.main-footer__icon {
    margin-right: 10px;
    width: 23px;
}

.main-footer__icon--mr-none {
    margin-right: 0;
}

.main-footer__row-2 {
    width: 40%;
    order: 1;
    max-width: 500px;
}

.heading-sm {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.main-footer__short-desc {
    margin-top: 20px;
    color: #eee;
    font-size: 13px;
    line-height: 1.7;
}

@media screen and (max-width: 576px) {
    .main-footer__upper {
        padding: 20px 0;
    }

    .main-footer__lower {
        padding: 20px 0;
    }
}