.about {
    background: #fafafa;
}

.main-container {
    max-width: 1248px;
    margin: auto;
    width: 92%;
}

.heading-sec__mb-med {
    margin-bottom: 90px;
}

.heading-sec__main {
    display: block;
    font-size: 37px;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: center;
    margin-bottom: 35px;
    position: relative;
    color: #111;
}

.heading-sec__main::after {
    content: '';
    position: absolute;
    top: calc(100% + 15px);
    height: 5px;
    width: 3rem;
    background: #facf0f;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
}

.about__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 80px;
}

.about__content-details-para {
    font-size: 18px;
    color: #666;
    max-width: 60rem;
    line-height: 1.7;
    margin-bottom: 14px;
}

.about__content-details-para a {
    text-decoration: underline;
    font-weight: 700;
    color: #facf0f;
    margin: 0 3px;
}

.about__content-details-para:last-child {
    margin-bottom: 44px;
}

.btn--theme {
    background: #facf0f;
    color: #111;
}

.btn--med {
    padding: 15.6px 52px;
    font-size: 16px;
}

.about__content-title {
    font-weight: 700;
    font-size: 27px;
    margin-bottom: 34px;
}

.skills {
    display: flex;
    flex-wrap: wrap;
}

.skills__skill {
    padding: 8px 16px;
    margin-bottom: 14px;
    margin-right: 14px;
    font-size: 16.64px;
    background: rgba(153, 153, 153, .2);
    border-radius: 5px;
    font-weight: 600;
    color: #666;
}

.heading-sec__sub {
    display: block;
    text-align: center;
    color: #555;
    font-size: 19px;
    font-weight: 500;
    max-width: 80rem;
    margin: auto;
    line-height: 1.6;
}

@media screen and (max-width: 576px) {
    .heading-sec__main {
        font-size: 26px;
        letter-spacing: 1px;
    }

    .heading-sec__sub {
        font-size: 16px;
    }

    .heading-sec__main::after {

        top: calc(100% + 5px);
    }

    .about__content {
        display: flex;
        grid-gap: 20px;
        flex-direction: column;
    }

    .heading-sec__mb-med {
        margin-bottom: 40px;
    }

    .about__content-main {
        text-align: center;
    }

    .about__content-title {

        text-align: center;

        margin-bottom: 20px;

    }

    .skills {
        justify-content: center;
    }

}