
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.btn {
  background: #facf0f;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  font-weight: 700;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
  transition: transform .3s;
}