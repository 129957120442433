.sec-pad {
    padding: 120px 0;
}

.heading-sec__mb-bg {
    margin-bottom: 111px;
}

.projects__row {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 50px;
    margin-bottom: 111px;
}

.projects__row-img-cont {
    overflow: hidden;
}

.projects__row-img {
    width: 100%;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
}

.projects__row-content {
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.projects__row-content-title {
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 20px;
}

.projects__row-content-desc {
    font-size: 18px;
    color: #666;
    max-width: 600px;
    line-height: 1.7;
    margin-bottom: 30px;
}

@media screen and (max-width: 576px) {
    .sec-pad {
        padding: 20px 0;
    }

    .projects__row {
        display: flex;
        grid-gap: 20px;
        flex-direction: column;
        margin-bottom: 40px;
    }

    .heading-sec__mb-bg {
        margin-bottom: 40px;
    }

    .projects__row-content {
        align-items: center;
    }
}